import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import randomstring from 'randomstring';
import style from '../../assets/css/commonListPage.module.css'
import inquiryStyle from './InquiryInsertPage.module.css';
import mobileLogo from '../../assets/images/security.png'
import '../../assets/css/common.css';
import { valueCpName, valueStaffName, valuePhone, valueEmail, valueUse, valuePrd, valueQuan, valueContent, isValidEmail } from './ValueInput';
import SideBannerModal from '../../components/Modal/SideBannerModal';

const InquiryInsertPage = () => {

    const iframeHtml = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="utf-8" />
            <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            
            <script src="https://cdn.polyfill.io/v2/polyfill.min.js"></script>
        
            <!-- 카카오 비즈니스 추가 -->
            <script type="text/javascript" charset="UTF-8" src="//t1.daumcdn.net/kas/static/kp.js"></script>
            <script type="text/javascript">
                kakaoPixel('7567550323681477133').purchase();
            </script>
            <!-- 카카오 비즈니스 추가 -->
        </head>
        </html>
    `;

    const [inquiryData, setInquiryInsert] = useState({
        PI_TITLE: 'EPR 리더기 제품 견적 문의',
        PI_COMPANY_NAME: '',
        PI_STAFF_NAME: '',
        PI_PHONE: '',
        PI_EMAIL: '',
        PI_USE: '',
        PI_PRODUCT: '',
        PI_QUANTITY: '',
        PI_CONTENT: '',
        PI_STATUS: 'Y',
        PI_ANSWER_STATUS: 'N',
    });
    
    const [isInquirySubmitted, setIsInquirySubmitted] = useState(false);

    const [allAgreed, setAllAgreed] = useState(false); 
    const [agreed, setAgreed] = useState(false);
    const [individualAgreements, setIndividualAgreements] = useState({
        phoneAgreeYn: false,
        emailAgreeYn: false,
    });


    const [securityCode, setSecurityCode] = useState('');
    const [userEnteredCode, setUserEnteredCode] = useState('');
    const [isCodeCorrect, setIsCodeCorrect] = useState(false);
    const [email, setEmail] = useState('');
    const emailInputRef = useRef(null);
    const [emailFormatErrorHandled, setEmailFormatErrorHandled] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        generateSecurityCode();
    }, []);

    const generateSecurityCode = () => {
        const code = randomstring.generate({ length: 5, charset: 'alphanumeric' });
        setSecurityCode(code);
    };

    const handleCodeChange = (e) => {
        setUserEnteredCode(e.target.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        

        if (name === 'PI_COMPANY_NAME') {
            const valueErr = valueCpName(value);
            if (valueErr) {
                alert(valueErr);
                return;
            }
        } else if (name === 'PI_STAFF_NAME') {
            const valueErr = valueStaffName(value);
            if (valueErr) {
                alert(valueErr);
                return;
            }
        } else if (name === 'PI_PHONE') {
            const valueErr = valuePhone(value);
            if (valueErr) {
                alert(valueErr);
                return;
            }
        // } else if (name === 'PI_EMAIL') {
        //     const valueErr = valueEmail(value);
        //     if (valueErr) {
        //         alert(valueErr);
        //         return;
        //     }
        } else if (name === 'PI_USE') {
            const valueErr = valueUse(value);
            if (valueErr) {
                alert(valueErr);
                return;
            }
        } else if (name === 'PI_PRODUCT') {
            const valueErr = valuePrd(value);
            if (valueErr) {
                alert(valueErr);
                return;
            }
        } else if (name === 'PI_QUANTITY') {
            const valueErr = valueQuan(value);
            if (valueErr) {
                alert(valueErr);
                return;
            }
        } else if (name === 'PI_CONTENT') {
            const valueErr = valueContent(value);
            if (valueErr) {
                alert(valueErr);
                return;
            }
        }
        
        setInquiryInsert((prevData) => ({
          ...prevData,
          [name]: value === undefined ? '' : value,
        }));
    };

    const handleBlur = () => {
        if (!isValidEmail(email) && !emailFormatErrorHandled) {
            setEmailFormatErrorHandled(true);
            alert('이메일이 형식에 맞지 않습니다');
            emailInputRef.current.focus();
            emailInputRef.current.value = '';
        } 
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        const { name, value } = e.target;
        
        setInquiryInsert((prevData) => ({
          ...prevData,
          [name]: value === undefined ? '' : value,
        }));
    };

    const handleAllAgreeChange = (e) => {
        const isChecked = e.target.checked;
        setAllAgreed(isChecked); 
        setAgreed(isChecked);
        setIndividualAgreements({
          phoneAgreeYn: isChecked,
          emailAgreeYn: isChecked,
        });
      };
    
    const handleIndividualChange = (e) => {
        const { name, checked } = e.target;
        if (name === "agreement") {
            setAgreed(checked); 
        } else {
            setIndividualAgreements((prev) => ({
                ...prev,
                [name]: checked,
            }));
        }
    
        setAllAgreed(
        name === "agreement"
            ? checked &&
              Object.values({ ...individualAgreements }).every((v) => v) 
            : agreed && Object.values({ ...individualAgreements, [name]: checked }).every((v) => v)
        );
      
    }; 

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userEnteredCode === securityCode) {
            setIsCodeCorrect(true);
        } else {
            setIsCodeCorrect(false);
            alert('보안문자를 입력해 주세요.');
            return;
        }

        if (
            !inquiryData.PI_COMPANY_NAME ||
            !inquiryData.PI_STAFF_NAME ||
            !inquiryData.PI_PHONE ||
            !inquiryData.PI_EMAIL
        ) {
            alert('이 입력란을 작성하세요.');
            return;
        }

        if (!agreed) {
            alert('개인정보 수집 및 이용동의를 해주세요.');
            return;
        }

        try {
            const response = await fetch('/api/inquiry/inquiryPost', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                inquiry: JSON.stringify(inquiryData),
                marketing: {
                    phoneAgreeYn: individualAgreements.phoneAgreeYn ? "Y" : "N",
                    emailAgreeYn: individualAgreements.emailAgreeYn ? "Y" : "N",
                },
              })
            });
        
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(
                  `Network response was not ok. Status: ${response.status}, Message: ${errorData.message}`
                );
            }

            // 문자
            const SType = 'SENSETAB';
            const MSG = 'EPR 리더기 제품 견적 문의';

            const msgUrl = `/CALL/SMS_CALL_EMFO_RF.emfo?SType=${SType}&MSG=${encodeURIComponent(MSG)}`;
            // const msgUrl = `http://call.emfo.co.kr:7575/CALL/SMS_CALL_EMFO_RF.emfo?SType=${SType}&MSG=${encodeURIComponent(MSG)}`;
            
            // console.log('msgUrl:', msgUrl);

            fetch(msgUrl)
                .then(msgResponse => {
                    if (!msgResponse.ok) {
                        throw new Error(`Network msgResponse was not ok. Status: ${msgResponse.status}`);
                    }
                    return msgResponse;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            
            setIsInquirySubmitted(true);
            setInquiryInsert({
                PI_TITLE: 'EPR 리더기 제품 견적 문의',
                PI_COMPANY_NAME: '',
                PI_STAFF_NAME: '',
                PI_PHONE: '',
                PI_EMAIL: '',
                PI_USE: '',
                PI_PRODUCT: '',
                PI_QUANTITY: '',
                PI_CONTENT: '',
                PI_STATUS: 'Y',
                PI_ANSWER_STATUS: 'N',
            });
            setAgreed(false);
            setUserEnteredCode('');
            setIsCodeCorrect(false);

            navigate(`/inquiry/success`);

            } catch (error) {
                console.error('Error submitting inquiry data:', error);
            }
        
    };

    return (
        <>
        <div dangerouslySetInnerHTML={{ __html: iframeHtml }} />
        
        <div className={style.mainTitleDiv}>
            <h2 className={style.mainTitle}>구매/견적문의</h2>
        </div>

        <div className={style.container}>

        <SideBannerModal />

            {/* <div className={style.title}>구매/견적 문의</div> */}
            <form onSubmit={handleSubmit}>

            <table className={inquiryStyle.table}>
                {/* <thead>
                    <tr>
                        <th className='w-20'>제목</th>
                        <th className='w-80'>
                            <input type="text" name="PI_TITLE" value={inquiryData.PI_TITLE} onChange={handleChange} disabled /> 
                            <p name="PI_TITLE" value={inquiryData.PI_TITLE} onChange={handleChange}>{inquiryData.PI_TITLE}</p>
                        </th>
                    </tr>
                </thead> */}
                <tbody>
                    <tr>
                        <td className='w-20'><span className={inquiryStyle.point} >*</span>업체명</td>
                        <td className='w-80'><input type="text" name="PI_COMPANY_NAME" value={inquiryData.PI_COMPANY_NAME} onChange={handleChange} placeholder='업체명' required /></td>
                    </tr>
                    <tr>
                        <td><span className={inquiryStyle.point} >*</span>담당자명</td>
                        <td><input type="text" name="PI_STAFF_NAME" value={inquiryData.PI_STAFF_NAME} onChange={handleChange} placeholder='담당자명' required /></td>
                    </tr>
                    <tr>
                        <td><span className={inquiryStyle.point} >*</span>연락처</td>
                        <td><input type="text" name="PI_PHONE" value={inquiryData.PI_PHONE} onChange={handleChange} placeholder='010-0000-0000' required /></td>
                    </tr>
                    <tr>
                        <td><span className={inquiryStyle.point} >*</span>E-Mail</td>
                        {/* <td><input type="text" name="PI_EMAIL" value={inquiryData.PI_EMAIL} onChange={handleChange} required /></td> */}
                        <td><input ref={emailInputRef} type="text" name="PI_EMAIL" value={inquiryData.PI_EMAIL} onChange={handleChangeEmail} onBlur={handleBlur} required /></td>
                    </tr>
                    <tr>
                        <td>사용용도</td>
                        <td><input type="text" name="PI_USE" value={inquiryData.PI_USE} onChange={handleChange} /></td>
                    </tr>
                    <tr>
                        <td>구매제품</td>
                        <td><input type="text" name="PI_PRODUCT" value={inquiryData.PI_PRODUCT} onChange={handleChange} /></td>
                    </tr>
                    <tr>
                        <td>구매수량</td>
                        <td><input type="text" name="PI_QUANTITY" value={inquiryData.PI_QUANTITY} onChange={handleChange} /></td>
                    </tr>
                    <tr>
                        <td>내용</td>
                        <td><textarea type="text" name="PI_CONTENT" value={inquiryData.PI_CONTENT} onChange={handleChange} placeholder='문의드립니다.'></textarea></td>
                    </tr>
                    {/* <tr>
                        <td><span className={inquiryStyle.point} >*</span>보안문자</td>
                        <td>

                            <div className={inquiryStyle.jbwrap}>
                                <div className={inquiryStyle.jbimage}><img src={mobileLogo} alt="" /></div>
                                <div className={inquiryStyle.jbtext}>
                                    <p onMouseDown={(e) => e.preventDefault()} readOnly>{securityCode}</p>
                                </div>
                            </div>
                            <p onClick={generateSecurityCode}>[새로고침]</p>
                            <a><input type="text" value={userEnteredCode} onChange={handleCodeChange} /></a>
                        </td>
                    </tr> */}
                </tbody>
            </table>

            <div className={inquiryStyle.security} >
                <div className={inquiryStyle.securityText} ><span className={inquiryStyle.point} >*</span>보안문자</div>
                <div  className={inquiryStyle.securityTextRight}>
                    <div className={inquiryStyle.jbwrap}>
                        <div className={inquiryStyle.jbimage}><img src={mobileLogo} alt="" /></div>
                        <div className={inquiryStyle.jbtext}>
                            <p onMouseDown={(e) => e.preventDefault()} readOnly>{securityCode}</p>
                        </div>
                    </div>
                    <div className={inquiryStyle.refresh}>
                        <p onClick={generateSecurityCode}>[새로고침]</p>
                        <a><input type="text" value={userEnteredCode} onChange={handleCodeChange} /></a>
                    </div>
                </div>
                
            </div>

            <div className={inquiryStyle.checkbox} style={{textAlign: 'center', fontWeight: '700'}}>
                <input type="checkbox" name="allAgreeYn" checked={allAgreed} onChange={handleAllAgreeChange} id="allAgreeYn" />
                <label htmlFor="allAgreeYn">전체동의</label> <br></br>
            </div>

            <div className={inquiryStyle.agreedBox}>
                <div className={inquiryStyle.agreedText}>
                    <p>개인정보 수집 및 이용동의(필수)</p>
                    <p>- 수집 항목 : 제목, 비밀번호, 업체명, 담당자명, 연락처, 이메일 및 그 외 개인이 직접 입력한 내용<br></br>
                        - 수집 목적 : 문의사항접수 및 결과 회신을 위한 경로 확보<br></br>
                        - 이용 기간 : 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.<br></br>
                        단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정 기간 동안 개인정보를<br></br>                    
                        보관할 수 있습니다.<br></br>
                        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)<br></br>
                        - 위의 개인정보 소집 및 이용에 동의합니다.(동의 후 문의가 가능합니다.)                    
                    </p>
                </div>
                <div className={inquiryStyle.checkbox}>
                    <input type="checkbox" name="agreement" checked={agreed} onChange={handleIndividualChange} required id="round-checkbox" />
                    <label htmlFor="round-checkbox">개인정보 수집 및 이용동의(필수)</label> <br></br>
                </div>
            </div>
            
            <div className={inquiryStyle.agreedBox}>
                <div className={inquiryStyle.agreedText}>
                    <p>마케팅 활용 동의 및 광고 수신 동의</p>
                    <p>서비스와 관련된 신상품 소식, 이벤트 안내, 고객 혜택 등 다양한 정보를 제공합니다.               
                    </p>
                </div>
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <div className={inquiryStyle.checkbox}>
                        <input type="checkbox" name="phoneAgreeYn" checked={individualAgreements.phoneAgreeYn} onChange={handleIndividualChange} id="phoneAgreeYn" />
                        <label htmlFor="phoneAgreeYn">문자 수신 동의(선택)</label> <br></br>
                    </div>
                    <div className={inquiryStyle.checkbox}>
                        <input type="checkbox" name="emailAgreeYn" checked={individualAgreements.emailAgreeYn} onChange={handleIndividualChange} id="emailAgreeYn" />
                        <label htmlFor="emailAgreeYn">E-Mail 수신 동의(선택)</label> <br></br>
                    </div>
                </div>
            </div>
                
            <div className={inquiryStyle.checkBtnDiv}>
                <button type="submit" className={style.checkBtn} >등록하기</button>
            </div>

            </form>

        </div> {/* container */}
        
        </>

    );

};

export default InquiryInsertPage;