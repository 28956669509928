import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import style from '../../assets/css/common.css'

import { popup01, popup02, popup03, popup04 } from '../../assets/images/Popup/popup'

const PopupModal = () => {

  const [isChecked, setIsChecked] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [isPopup2Visible, setIsPopup2Visible] = useState(false);

  
  useEffect(() => {
    const currentDate = new Date();
    const currentDateFormatted = currentDate.toISOString().slice(0, 10);

    const showPopupDate = new Date('2025-01-10');

    if (currentDate < showPopupDate) {
      setIsPopupVisible(false);
      setIsPopup2Visible(true);
    } else {
      setIsPopupVisible(true);
      setIsPopup2Visible(false);
    }

    const isPopupHidden = localStorage.getItem('hidePopup');

    if (isPopupHidden) {
      setIsPopupVisible(false);
      setIsPopup2Visible(false);
    }
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCloseButtonClick = () => {
    if (isChecked) {
      localStorage.setItem('hidePopup', 'true');
    }
    setIsPopupVisible(false);
    setIsPopup2Visible(false);
  };

  return (
    <>
      {isPopupVisible && (
        <div>
          {/* 1 */}
          <div className='popup'>
            <div className='popupTitle'>
              <a>센스탭 소식통</a>
            </div>
            <div className='popupBox'>
              <div className='popupBoxFirst'>
                <div>
                  <Link to={'http://sensetab.co.kr/notice/detail/438'} target="_blank"><img src={popup01} alt='카드제작'/></Link>
                </div>
                <div>
                  <Link to={'http://sensetab.co.kr/notice/detail/436'} target="_blank"><img src={popup02} alt='구매견적문의'/></Link>
                </div>
              </div>
              <div className='popupBoxLast'>
                <div>
                  <Link to={'http://sensetab.co.kr/notice/detail/447'} target="_blank"><img src='https://i.imgur.com/220vWSC.jpeg' /></Link>
                </div>
                {/* <div>
                  <Link to={'http://sensetab.co.kr/product/intro'} target="_blank"><img src={popup04} alt='태그제작'/></Link>
                </div> */}
                <div>
                  <Link to={'http://emfoplus.co.kr/cscenter/noticeContent?N_Idx=288&page=1&line=10&SearchType=&SearchText='} target="_blank">
                    <img src="https://i.imgur.com/WnabyIk.jpg" width={300}/>
                  </Link>
                </div>
              </div>
              {/* <div className='popupBoxLast_2'>
                
              </div> */}
            </div>
            <div className='popupBtnBox'>
              <a className='popupBtnA'>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="0"
                    className='cookiepop'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span style={{ cursor: 'pointer' }}>오늘 하루 보지 않기</span>
                </div>
                <input
                  type="button"
                  value="닫기"
                  className='closeBtn'
                  style={{ cursor: 'pointer' }}
                  onClick={handleCloseButtonClick}
                />
              </a>
            </div>
          </div>
        </div>        
      )}
      {isPopup2Visible && (
        <div>
          {/* 2 */}
          <div className='popup_02'>
            <div className='popupTitle'>
              <a>센스탭 소식통</a>
            </div>
            <div className='popupBox'>
              <div className='popupBoxFirst'>
                <div>
                  <Link to={'http://sensetab.co.kr/notice/detail/438'} target="_blank"><img src={popup01} alt='카드제작'/></Link>
                </div>
                <div>
                  <Link to={'http://sensetab.co.kr/notice/detail/436'} target="_blank"><img src={popup02} alt='구매견적문의'/></Link>
                </div>
              </div>
              <div className='popupBoxLast'>
                <div>
                  <Link to={'http://sensetab.co.kr/notice/detail/435'} target="_blank"><img src={popup03} alt='태그제작'/></Link>
                </div>
                <div>
                  <Link to={'http://sensetab.co.kr/product/intro'} target="_blank"><img src={popup04} alt='태그제작'/></Link>
                </div>
              </div>
              
              <div className='popupBoxLast_2'>
                <div>
                  <Link to={'https://sensetab.co.kr/notice/detail/445'} target="_blank">
                    <img src="https://i.imgur.com/RVkdg6Q.jpeg" width={300}/>
                  </Link>
                </div>
              </div>
            </div>
            <div className='popupBtnBox'>
              <a className='popupBtnA'>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="0"
                    className='cookiepop'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span style={{ cursor: 'pointer' }}>오늘 하루 보지 않기</span>
                </div>
                <input
                  type="button"
                  value="닫기"
                  className='closeBtn'
                  style={{ cursor: 'pointer' }}
                  onClick={handleCloseButtonClick}
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupModal;
